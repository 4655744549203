import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/store";
import {addProduct, getProducts} from "../../store/products";
import IProduct from "../../utils/interfaces/IProduct";
import {generateProducts} from "../../utils/functions";
import ProductsList from "../../components/products/ProductsList/ProductsList";
import Layout from "../Layout/Layout";

const HomeScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const products = useAppSelector(state => getProducts(state));

    useEffect(() => {
        makeProducts();
    }, []);

    const makeProducts = async () => {
        if (products.length <= 0) {
            generateProducts().then((generatedProducts: Array<IProduct>) => {
                generatedProducts.forEach((product: IProduct) => {
                    dispatch(addProduct(product));
                })
            }).catch(() => alert("fout bij het aanmaken van producten"));
        }
    }

    const renderProductsList = () => {
        if (products.length <= 0) {
            return (
                <div>
                    Geen producten om weer te geven.
                </div>
            )
        }

        return (
            <ProductsList />
        );
    }

    return (
        <Layout>
            {renderProductsList()}
        </Layout>
    )
}

export default HomeScreen;