import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as Logo} from '../../../assets/images/logo.svg';
import "./header.scss";
import {useAppSelector} from "../../../hooks/store";
import {getCartItems} from "../../../store/cart";
import {Badge, IconButton} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import ICartItem from "../../../utils/interfaces/ICartItem";
import {useNavigate} from "react-router-dom";
import {generateAvatarChars} from "../../../utils/functions";
import {getUser} from "../../../store/auth";

const Header: React.FC = () => {
    const ref = useRef<any>(null)
    const navigate = useNavigate();
    const cartItems = useAppSelector(state => getCartItems(state));
    const user = useAppSelector(state => getUser(state));
    const [cartCount, setCartCount] = useState(0);
    const [height, setHeight] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        // set height of header
        setHeight(ref?.current?.clientHeight ? ref.current.clientHeight : 0);
        // set listener to het scroll position
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        calculateCartCount();
    }, [cartItems]);

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    const calculateCartCount = () => {
        /** better to handle this in the reducer instead of in this component
         * looping over a lot of products will decrease fastness
         * **/
        let count = 0;
        cartItems.map((cartItem: ICartItem) => {
            count += cartItem.quantity
        });
        setCartCount(count);
    }

    const navigateToCart = () => {
        navigate("/cart");
    }

    const navigateToHome = () => {
        navigate("/");
    }

    const navigateToLogin = () => {
        navigate("/login");
    }

    const navigateToAccount = () => {
        navigate("/account");
    }

    const renderAvatar = () => {
        if (user.email) {
            return (
                <div className={"headerAvatar hoverBlock"} onClick={navigateToAccount}>
                    {generateAvatarChars(user.first_name, user.initials, user.last_name)}
                </div>
            );
        } else {
            return (
                <div className={"headerAvatar hoverBlock"} onClick={navigateToLogin}>
                    <PersonIcon className={"headerAvatarIcon"} />
                </div>
            );
        }
    }

    return (
        <>
            <div
                id={"header"}
                ref={ref}
            >
                <div className={"logo"} onClick={navigateToHome}>
                    <Logo className={"logoImage"} />
                    <span className={"logoText"}><b>React Test Shop</b></span>
                </div>
                <div className={"actions"}>
                    <IconButton onClick={navigateToCart}>
                        <Badge badgeContent={cartCount} color="secondary">
                            <ShoppingCartIcon className={"headerCartIcon"} />
                        </Badge>
                    </IconButton>
                    {renderAvatar()}
                </div>
            </div>
        </>
    )
}

export default Header;