import React from "react";
import {Card, CardMedia, Grid, Typography,} from '@mui/material';
import "./orderItem.scss";
import IOrderItem from "../../../utils/interfaces/IOrderItem";

interface IProps {
    orderItem: IOrderItem
}
const OrderItem: React.FC<IProps> = ({
    orderItem,
}) => {
    const price = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(orderItem.price);
    const totalPrice = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(orderItem.price * orderItem.quantity);

    return (
        <Card className={"orderItem"}>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Grid
                    item
                    xs={12}
                    sm={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{display: "flex"}}
                >
                    <CardMedia
                        component="img"
                        alt={orderItem.product.images[0].name}
                        image={orderItem.product.images[0].url}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    className="orderItemTitleContainer"
                >
                    <Typography variant="h6" className={"orderItemTitle"}>{orderItem.product.name}</Typography>
                    <Typography
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                        }}
                        variant="body2"
                        color="text.secondary"
                    >
                        {orderItem.product.description}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className="orderItemPriceContainer"
                    justifyContent="flex-end"
                >
                    <div className="orderItemQuantity">
                        <Typography sx={{marginTop: 1}}>aantal: <b>{orderItem.quantity}</b></Typography>
                        <Typography sx={{marginTop: 1}}>prijs: <b>{price}</b></Typography>
                        <Typography sx={{marginTop: 1}}>totaal: <b>{totalPrice}</b></Typography>
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
}

export default OrderItem;