import React, {useState} from "react";
import Layout from "../Layout/Layout";
import './loginScreen.scss';
import {useNavigate} from "react-router-dom";
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {addUser, getUsers, loginUser, mutateUser} from "../../store/auth";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../hooks/store";
import {toast} from "react-toastify";

const LoginScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const users = useAppSelector(state => getUsers(state));
    const [email, setEmail] = useState<string>("");
    const navigateToRegister = () => {
        navigate("/register");
    }

    const submit = () => {
        const existingUser = users.find((existingUser) => existingUser.email === email);

        if (existingUser) {
            dispatch(loginUser(existingUser));
            setTimeout(() =>  navigate("/account?login=true"), 1500);
        } else {
            toast.error(`Geen gebruiker gevonden met email ${email}`);
        }
    }

    return (
        <Layout>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar className={"signAvatar"}>
                        <Logo className={"signAvatarLogo"}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Inloggen
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Wachtwoord"
                            type="password"
                            id="password"
                        />
                        <Button
                            size="medium"
                            fullWidth
                            variant={"contained"}
                            onClick={submit}
                            sx={{ mt: 3, mb: 2 }}
                        >Inloggen</Button>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link onClick={navigateToRegister} variant="body2">
                                    {"Heeft u nog geen account? Registeren"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Layout>
    )
}

export default LoginScreen;