import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/store";
import Layout from "../Layout/Layout";
import ICartItem from "../../utils/interfaces/ICartItem";
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import './checkoutScreen.scss';
import {getUser, mutateUser} from "../../store/auth";
import CartItem from "../../components/cart/CartItem/CartItem";
import {countriesArray} from "../../utils/constants";
import {addOrder} from "../../store/orders";
import IOrder from "../../utils/interfaces/IOrder";
import IOrderItem from "../../utils/interfaces/IOrderItem";
import {getRandomNumber} from "../../utils/functions";
import OrderModal from "../../components/orders/OrderModal/OrderModal";
import {removeAllProductsFromCart} from "../../store/cart";

const CheckoutScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const cart = useAppSelector(state => state.cart);
    const user = useAppSelector(state => getUser(state));
    const orders = useAppSelector(state => state.orders.orders);
    const [totalCartPrice, setTotalCartPrice] = useState("");
    const [localCart, setLocalCart] = useState(cart);
    const [enableInvoiceAddress, setEnableInvoiceAddress] = useState(false);
    const [acceptedConditions, setAcceptedConditions] = useState(false);
    const [errors, setErrors] = useState(new Array<{field: string, error: string}>());
    const [createdOrderNumber, setCreatedOrderNumber] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState<IOrder | undefined>(orders[12]);

    useEffect(() => {
        calculateTotalPrice();
        checkAuthentication();
    }, []);

    useEffect(() => {
        checkIfOrderIsPlaced();
    }, [orders]);

    const checkIfOrderIsPlaced = () => {
        const orderFromStore = orders.find((oFS) => oFS.orderNumber === createdOrderNumber);
        if (orderFromStore?.orderNumber) {
            setOrder(orderFromStore);
            dispatch(removeAllProductsFromCart());
            setLoading(false);
        }
    }
    const checkAuthentication = () => {
        if (user.email !== '') {
            const newLocalCart = {
                ...localCart,
                email: user.email,
                first_name: user.first_name,
                initials: user.initials,
                last_name: user.last_name,
                delivery_address: user.delivery_address,
                delivery_zipcode: user.delivery_zipcode,
                delivery_city: user.delivery_city,
                delivery_country: user.delivery_country,
                invoice_address: user.invoice_address,
                invoice_zipcode: user.invoice_zipcode,
                invoice_city: user.invoice_city,
                invoice_country: user.invoice_country,
            };
            setLocalCart(newLocalCart);
        }
    }

    const calculateTotalPrice = () => {
        /** it will be better for performance to do this calculation when adding an item instead in this function **/
        let price: number = 0;

        cart.items.map((cartItem: ICartItem) => {
            const totalPrice = cartItem.product.price * cartItem.quantity;
            price += totalPrice;
        });

        const formattedPrice: string = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);


        setTotalCartPrice(formattedPrice);
    }

    const changeCart = (field: string, value: string) => {
        const newLocalCart = {
            ...localCart,
            email: field === 'email' ? value : localCart.email,
            first_name: field === 'first_name' ? value : localCart.first_name,
            initials: field === 'initials' ? value : localCart.initials,
            last_name: field === 'last_name' ? value : localCart.last_name,
            delivery_address: field === 'delivery_address' ? value : localCart.delivery_address,
            delivery_zipcode: field === 'delivery_zipcode' ? value : localCart.delivery_zipcode,
            delivery_city: field === 'delivery_city' ? value : localCart.delivery_city,
            delivery_country: field === 'delivery_country' ? value : localCart.delivery_country,
            invoice_address: field === 'invoice_address' ? value : localCart.invoice_address,
            invoice_zipcode: field === 'invoice_zipcode' ? value : localCart.invoice_zipcode,
            invoice_city: field === 'invoice_city' ? value : localCart.invoice_city,
            invoice_country: field === 'invoice_country' ? value : localCart.invoice_country
        };
        setLocalCart(newLocalCart);
    }

    const submit = () => {
        setLoading(true);
        const newErrors = [];
        if (!acceptedConditions) {
            newErrors.push({field: "conditions", error: "De algemene voorwaarden moeten worden geaccepteerd om een bestelling te kunnen plaatsen"});
        }

        for (const [key, value] of Object.entries(localCart)) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const dutchZipcodeRegex = /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/;

            if (!enableInvoiceAddress && key.includes("invoice")) {
                continue;
            }

            if (key === "email") {
                if (!emailRegex.test(value)) {
                    newErrors.push({field: "email", error: "Email is niet geldig"});
                }
            }

            if (key === "delivery_zipcode" || key === "invoice_zipcode") {
                const country = (key === "delivery_zipcode") ? localCart.delivery_country : localCart.invoice_country;

                if (key === "invoice_zipcode" && !enableInvoiceAddress) {
                    continue;
                }

                if (country === "Nederland" && !dutchZipcodeRegex.test(value)) {
                    newErrors.push({field: key, error: "Postcode is niet geldig"});
                }
            }

            if (
                key !== "initials" &&
                key !== "delivery_zipcode" &&
                key !== "invoice_zipcode" &&
                key !== "email" && !value
            ) {
                newErrors.push({field: key, error: "Het veld mag niet leeg zijn"});
            }
        }

        setErrors(newErrors);

        if (newErrors.length > 0) {
            setLoading(false);
            return;
        }

        placeOrder();

    }

    const placeOrder = () => {
        let totalPrice = 0;
        let orderItems = new Array<IOrderItem>();

        for (const [key, value] of Object.entries(cart.items)) {
            orderItems.push(
                {
                    id: parseInt(key),
                    product: value.product,
                    price: value.product.price,
                    quantity: value.quantity
                } as IOrderItem
            );
            totalPrice += value.product.price;
        }

        const newOrder: IOrder = {
            id: getRandomNumber(100, 999),
            orderNumber: getRandomNumber(1000, 9999),
            price: totalPrice,
            user_id: getRandomNumber(100, 999),
            email: localCart.email,
            first_name: localCart.first_name,
            initials: localCart.initials,
            last_name: localCart.last_name,
            delivery_address: localCart.delivery_address,
            delivery_zipcode: localCart.delivery_zipcode,
            delivery_city: localCart.delivery_city,
            delivery_country: localCart.delivery_country,
            invoice_address: enableInvoiceAddress ? localCart.invoice_address : localCart.delivery_address,
            invoice_zipcode: enableInvoiceAddress ? localCart.invoice_zipcode : localCart.delivery_zipcode,
            invoice_city: enableInvoiceAddress ? localCart.invoice_city : localCart.delivery_city,
            invoice_country: enableInvoiceAddress ? localCart.invoice_country : localCart.delivery_country,
            items: orderItems
        }

        setCreatedOrderNumber(newOrder.orderNumber);

        setTimeout(() => {
            // mutate auth user
            dispatch(mutateUser({
                ...user,
                delivery_address: localCart.delivery_address,
                delivery_zipcode: localCart.delivery_zipcode,
                delivery_city: localCart.delivery_city,
                delivery_country: localCart.delivery_country,
                invoice_address: localCart.invoice_address,
                invoice_zipcode: localCart.invoice_zipcode,
                invoice_city: localCart.invoice_city,
                invoice_country: localCart.invoice_country,
            }))
            // place order
            dispatch(addOrder(newOrder))
        }, 1000);
    }

    const hasError = (field: string) => {
        const fieldError = errors.find((error) => error.field === field);
        return !!fieldError;
    }

    const renderAddressForm = (type: "delivery" | "invoice" ) => {
        return (
            <>
                <TextField
                    required
                    id={`${type}_address`}
                    label="Adres"
                    placeholder="Straat 1"
                    onChange={(event) => changeCart(`${type}_address`, event.target.value)}
                    value={localCart[`${type}_address`]}
                    error={hasError(`${type}_address`)}
                />
                <TextField
                    required
                    id={`${type}_zipcode`}
                    label="Postcode"
                    placeholder="1234 AB"
                    onChange={(event) => changeCart(`${type}_zipcode`, event.target.value)}
                    value={localCart[`${type}_zipcode`]}
                    sx={{marginTop: 2}}
                    error={hasError(`${type}_zipcode`)}
                />
                <TextField
                    required
                    id={`${type}_city`}
                    label="Plaatsnaam"
                    placeholder="Stad"
                    onChange={(event) => changeCart(`${type}_city`, event.target.value)}
                    value={localCart[`${type}_city`]}
                    sx={{marginTop: 2}}
                    error={hasError(`${type}_city`)}
                />
                <Autocomplete
                    id={`${type}_country`}
                    options={countriesArray}
                    onChange={(event, value) => changeCart(`${type}_country`, value?.label ?? "")}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            required
                            label="Land"
                            placeholder="Nederland"
                            value={localCart[`${type}_country`]}
                            sx={{marginTop: 2}}
                            error={hasError(`${type}_country`)}
                        />
                }
                />
            </>
        )
    }

    return (
        <Layout>
            <div className="checkoutScreen">
                <div className={"checkoutTitle"}>
                    <ShoppingCartCheckoutIcon className={"checkoutTitleIcon"} />
                    <Typography variant="h5" className={"checkoutTitleText"}>Uitchecken</Typography>
                </div>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    padding={2}
                    className="checkoutGrid"
                >
                    <Grid item xs={12} md={6}>
                        <Card className={"checkoutForm"}>
                            <CardContent className={"checkoutFormBlock"}>
                                <Typography variant="h6" className={"checkoutContentTitle"}>Persoonsgegevens</Typography>
                                <Divider className="checkoutContentDivider"/>
                                <TextField
                                    required
                                    id="email"
                                    label="Email"
                                    placeholder="info@harkeroelof.nl"
                                    value={localCart.email}
                                    onChange={(event) => changeCart('email', event.target.value)}
                                    error={hasError(`email`)}
                                />
                                <TextField
                                    required
                                    id="first_name"
                                    label="Voornaam"
                                    placeholder="Jan"
                                    value={localCart.first_name}
                                    onChange={(event) => changeCart('first_name', event.target.value)}
                                    sx={{marginTop: 1}}
                                    error={hasError(`first_name`)}
                                />
                                <TextField
                                    id="initials"
                                    label="Tussenvoegsel(s)"
                                    placeholder=""
                                    value={localCart.initials}
                                    onChange={(event) => changeCart('initials', event.target.value)}
                                    sx={{marginTop: 2}}
                                    error={hasError(`initials`)}
                                />
                                <TextField
                                    required
                                    id="last_name"
                                    label="Achternaam"
                                    placeholder="Jansen"
                                    value={localCart.last_name}
                                    onChange={(event) => changeCart('last_name', event.target.value)}
                                    sx={{marginTop: 2}}
                                    error={hasError(`last_name`)}
                                />
                            </CardContent>
                        </Card>
                        <Card className={"checkoutForm"} sx={{marginTop: 2}}>
                            <CardContent className={"checkoutFormBlock"}>
                                <Typography variant="h6" className={"checkoutContentTitle"}>Bezorgadres</Typography>
                                <Divider className="checkoutContentDivider"/>
                                {renderAddressForm("delivery")}
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{marginTop: 2}}
                                        control={<Checkbox checked={!enableInvoiceAddress}
                                        onChange={(event) => setEnableInvoiceAddress(!enableInvoiceAddress)}/>}
                                        label="Het factuuradres is hetzelfde als het bezorgadres"
                                    />
                                </FormGroup>
                            </CardContent>
                        </Card>
                        {enableInvoiceAddress ?
                            <Card className={"checkoutForm"} sx={{marginTop: 2}}>
                                <CardContent className={"checkoutFormBlock"}>
                                    <Typography variant="h6" className={"checkoutContentTitle"}>Factuuradres</Typography>
                                    <Divider className="checkoutContentDivider"/>
                                    {renderAddressForm("invoice")}
                                </CardContent>
                            </Card>
                        : null}
                        <div className={"checkoutActions"}>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{marginTop: 2}}
                                    control={<Checkbox
                                    checked={acceptedConditions}
                                    onChange={(event) => setAcceptedConditions(!acceptedConditions)}/>}
                                    label="Ik ga akkoord met de algemene voorwaarden."
                                />
                            </FormGroup>
                            <div>
                                <Button
                                    size="large"
                                    variant={"contained"}
                                    sx={{marginTop: 2}}
                                    onClick={() => submit()}
                                    disabled={loading}
                                >{loading ? <CircularProgress className={"checkoutLoading"} /> : null} Bestelling plaatsen</Button>
                            </div>
                        </div>
                        <div className={"checkoutErrors"}>
                            {errors.map((error) => {
                                if (error.field === "email" || error.field === "conditions" || error.field.includes("zipcode")) {
                                    return (
                                        <Typography
                                            variant="body2"
                                            className={"checkoutError"}
                                        >- {error.error}</Typography>
                                    );
                                }
                            })}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Card className={"checkoutContent"}>
                            <CardContent>
                                <Typography variant="h6" className={"checkoutContentTitle"}>Overzicht</Typography>
                                <Divider className="checkoutContentDivider"/>
                                <Typography variant="body2" className={"checkoutContentTitle"}>Artikelen ({cart.items.length}): <b>{totalCartPrice}</b></Typography>
                                {cart.items.map((cartItem: ICartItem) =>
                                    <div style={{marginTop: "16px"}} key={`cart_item_${cartItem.product.id}`}>
                                        <CartItem cartItem={cartItem} outsideCart={true} unchangeable={true}/>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div>
            {order?.id ?
                <OrderModal open={!!order} onClose={() => setOrder(undefined)} order={order} />
            : null}
        </Layout>
    )
}

export default CheckoutScreen;