import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'
import IProduct from "../utils/interfaces/IProduct";

interface ProductsState {
    products: Array<IProduct>
}

const initialState: ProductsState = {
    products: new Array<IProduct>(),
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        addProduct: (state, action: PayloadAction<IProduct>) => {
            const product = state.products.find((stateProduct: IProduct) =>
                stateProduct.name === action.payload.name
            );
            if (!product) {
                state.products.push(action.payload);
            }
        },
        deleteProduct: (state, action: PayloadAction<number>) => {
           const index = state.products.findIndex((stateProduct: IProduct) =>
               stateProduct.id === action.payload
           );

           if (index >= 0) {
               state.products.splice(index, 1);
           }
        }
    },
})

export const {
    addProduct,
    deleteProduct
} = productsSlice.actions

// selectors
export const getProducts = (state: RootState) => state.products.products

export const getProductById = (state: RootState, id: number) => state.products.products.find((product) => product.id === id);

export default productsSlice.reducer