import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'
import IUser from "../utils/interfaces/IUser";
import {toast} from "react-toastify";

interface AuthState {
    user: IUser;
    users: Array<IUser>;
}

const initialState: AuthState = {
    user : {
        id: -1,
        email: "",
        password: "",
        first_name: "",
        initials: "",
        last_name: "",
        delivery_address: "",
        delivery_zipcode: "",
        delivery_city: "",
        delivery_country: "",
        invoice_address: "",
        invoice_zipcode: "",
        invoice_city: "",
        invoice_country: "",
    },
    users: new Array<IUser>()
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        mutateUser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload
            const index = state.users.findIndex((existingUser) =>
                existingUser.id === action.payload.id
            );
            if (index >= 0) {
                state.users[index] = action.payload;
            }
        },
        addUser: (state, action: PayloadAction<IUser>) => {
            state.users.push(action.payload);
            state.user = action.payload;
            toast.success("U bent ingelogd!");
        },
        loginUser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload;
            toast.success("U bent ingelogd!");
        },
        logoutUser: (state) => {
            state.user = {
                id: -1,
                email: "",
                password: "",
                first_name: "",
                initials: "",
                last_name: "",
                delivery_address: "",
                delivery_zipcode: "",
                delivery_city: "",
                delivery_country: "",
                invoice_address: "",
                invoice_zipcode: "",
                invoice_city: "",
                invoice_country: "",
            };
            toast.success("U bent uitgelogd.");
        }
    },
});

export const {
    mutateUser,
    addUser,
    loginUser,
    logoutUser
} = authSlice.actions

// selectors
export const getUser = (state: RootState) => state.auth.user;

export const getUsers = (state: RootState) => state.auth.users;

export default authSlice.reducer