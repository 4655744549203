import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks/store";
import Layout from "../Layout/Layout";
import ICartItem from "../../utils/interfaces/ICartItem";
import CartItem from "../../components/cart/CartItem/CartItem";
import {Button, Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './cartScreen.scss';
import {useNavigate} from "react-router-dom";


const CartScreen: React.FC = () => {
    const navigate = useNavigate();
    const cart = useAppSelector(state => state.cart);
    const [totalCartPrice, setTotalCartPrice] = useState("");

    useEffect(() => {
        calculateTotalPrice();
    }, [cart]);

    const calculateTotalPrice = () => {
        /** it will be better for performance to do this calculation when adding an item instead in this function **/
        let price: number = 0;

        cart.items.map((cartItem: ICartItem) => {
            const totalPrice = cartItem.product.price * cartItem.quantity;
            price += totalPrice;
        });

        const formattedPrice: string = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);


        setTotalCartPrice(formattedPrice);
    }

    const renderCartItems = () => {
        if (cart.items.length > 0) {
            return (
                <>
                {cart.items.map((cartItem: ICartItem) =>
                    <Grid
                        key={`cart_item_product_${cartItem.product.id}`}
                        item
                        xs={12}
                        style={{marginBottom: 16}}
                    >
                        <CartItem cartItem={cartItem}/>
                    </Grid>
                )}
                </>
            );
        } else {
            return (
                <>
                    <Card className={"emptyCartItems"}>
                        <Typography variant="body2" className={"cartTitleText"}>Geen producten in winkelwagentje</Typography>
                    </Card>
                </>
            );
        }
    }

    return (
        <Layout>
            <div className="cartScreen">
                <div className={"cartTitle"}>
                    <ShoppingCartIcon className={"cartTitleIcon"} />
                    <Typography variant="h5" className={"cartTitleText"}>Winkelwagentje</Typography>
                </div>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    padding={2}
                >
                    <Grid item xs={12} md={6}>
                        {renderCartItems()}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card className={"cartContent"}>
                            <CardContent>
                                <Typography variant="h6" className={"cartContentTitle"}>Overzicht</Typography>
                                <Divider className="cartContentDivider"/>
                                <Typography variant="body2" className={"cartContentTitle"}>Artikelen ({cart.items.length}): <b>{totalCartPrice}</b></Typography>
                                <Button
                                    size="large"
                                    variant={"contained"}
                                    sx={{marginTop: 2}}
                                    onClick={() => navigate("/cart/checkout")}
                                >Verder met bestellen</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <div className={"cartActions"}>

                </div>
            </div>
        </Layout>
    )
}

export default CartScreen;