import React, {useState} from "react";
import Layout from "../Layout/Layout";
import './registerScreen.scss';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import IUser from "../../utils/interfaces/IUser";
import {getRandomNumber} from "../../utils/functions";
import {useDispatch} from "react-redux";
import {addUser, getUsers} from "../../store/auth";
import {useAppSelector} from "../../hooks/store";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const RegisterScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const users = useAppSelector(state => getUsers(state));
    const [user, setUser] = useState<IUser>({
        id: getRandomNumber(1000, 9999),
        email: "",
        password: "",
        first_name: "",
        initials: "",
        last_name: "",
        delivery_address: "",
        delivery_zipcode: "",
        delivery_city: "",
        delivery_country: "",
        invoice_address: "",
        invoice_zipcode: "",
        invoice_city: "",
        invoice_country: "",
    });
    const [errors, setErrors] = useState(new Array<string>());

    const navigateToLogin = () => {
        navigate("/login");
    }

    const changeUser = (key: string, value: string) => {
        const newUser = {
            ...user,
            email: key === 'email' ? value : user.email,
            password: key === 'password' ? value : user.password,
            first_name: key === 'first_name' ? value : user.first_name,
            initials: key === 'initials' ? value : user.initials,
            last_name: key === 'last_name' ? value : user.last_name,
        };
        setUser(newUser);
    }

    const submit = () => {
        const newErrors = new Array<string>();
        for (const [key, value] of Object.entries(user)) {
            if (
                (
                    key === "email" ||
                    key === "password" ||
                    key === "first_name" ||
                    key === "last_name"
                ) && !value
            ) {
                newErrors.push(key);
            }
        }

        setErrors(newErrors);

        if (newErrors.length > 0) {
            return;
        }

        const existingUser = users.find((existingUser) => existingUser.email === user.email);

        if (existingUser) {
            toast.error(`Er bestaat al een gebruiker met email ${user.email}`);
            return;
        }

        dispatch(addUser(user));
        setTimeout(() => navigate("/account?registered=true"), 1500);
    }

    return (
        <Layout>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar className={"signAvatar"}>
                        <Logo className={"signAvatarLogo"}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                       Registreren
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    name="first_name"
                                    required
                                    fullWidth
                                    id="first_name"
                                    label="Voornaam"
                                    autoFocus
                                    error={errors.includes('first_name')}
                                    value={user.first_name}
                                    onChange={(event) => changeUser('first_name', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="initials"
                                    label="Initialen"
                                    name="initials"
                                    value={user.initials}
                                    onChange={(event) => changeUser('initials', event.target.value)}

                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="last_name"
                                    label="Achternaam"
                                    name="last_name"
                                    error={errors.includes('last_name')}
                                    value={user.last_name}
                                    onChange={(event) => changeUser('last_name', event.target.value)}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    error={errors.includes('email')}
                                    value={user.email}
                                    onChange={(event) => changeUser('email', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Wachtwoord"
                                    type="password"
                                    id="password"
                                    error={errors.includes('password')}
                                    value={user.password}
                                    onChange={(event) => changeUser('password', event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            size="medium"
                            fullWidth
                            variant={"contained"}
                            onClick={submit}
                            sx={{ mt: 3, mb: 2 }}
                        >Registreren</Button>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link onClick={navigateToLogin} variant="body2">
                                    Heeft u al een account? Inloggen
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Layout>
    );
}

export default RegisterScreen;