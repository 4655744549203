import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CardMedia, Grid,
    Modal,
    Paper, Table,
    TableBody, TableCell,
    TableContainer, TableRow,
    Typography,
} from '@mui/material';
import "./orderModal.scss";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useNavigate} from "react-router-dom";
import {generateFullName, getWindowDimensions} from "../../../utils/functions";
import IOrder from "../../../utils/interfaces/IOrder";
import IOrderItem from "../../../utils/interfaces/IOrderItem";
import OrderItem from "../OrderItem/OrderItem";
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    open: boolean;
    onClose: () => void;
    order: IOrder;
}
const OrderModel: React.FC<IProps> = ({
    open,
    onClose,
    order
}) => {
    const navigate = useNavigate();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function handleWindowResize() {
        setWindowDimensions(getWindowDimensions());
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="orderModalContainer"
        >
            <div className="orderModal">
                <div className="orderModalHeader">
                    <Typography variant="h5"><b>Uw order met nummer {order.orderNumber} is geplaatst!</b></Typography>
                    <Button
                        size="large"
                        variant={"text"}
                        startIcon={<CloseIcon/>}
                        onClick={onClose}
                    >{windowDimensions.width < 500 ? '' : 'Afsluiten'}</Button>
                </div>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} md={6}>
                        {order.items.map((orderItem: IOrderItem) =>
                            <OrderItem key={`order_item_${orderItem.id}`} orderItem={orderItem}/>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TableContainer className={"orderModalDetails"}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Naam</TableCell>
                                        <TableCell align="right">{generateFullName(order.first_name, order.initials, order.last_name)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Email</TableCell>
                                        <TableCell align="right">{order.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Bezorgadres</TableCell>
                                        <TableCell align="right">
                                            {order.delivery_address}<br/>
                                            {order.delivery_zipcode}<br/>
                                            {order.delivery_city}<br/>
                                            {order.delivery_country}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Factuuradres</TableCell>
                                        <TableCell align="right">
                                            {order.invoice_address}<br/>
                                            {order.invoice_zipcode}<br/>
                                            {order.invoice_city}<br/>
                                            {order.invoice_country}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
}

export default OrderModel;