import React, {useState} from "react";
import IProduct from "../../../utils/interfaces/IProduct";
import "./productCard.scss";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import {useAppDispatch} from "../../../hooks/store";
import {addProductToCart} from "../../../store/cart";
import ICartItem from "../../../utils/interfaces/ICartItem";
import IProductSpecification from "../../../utils/interfaces/IProductSpecification";
import CartModal from "../../cart/CartModal/CartModal";
import {useNavigate} from "react-router-dom";
import IProductImage from "../../../utils/interfaces/IProductImage";

interface IProps {
    product: IProduct;
    cartItem?: ICartItem;
    big?: boolean;
}
const ProductCard: React.FC<IProps> = ({
    product,
    cartItem,
    big
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const price = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(product.price);
    const [showCartModal, setShowCartModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(product.images[0]);
    const [bigImageClassName, setBigImageClassName] = useState<"hide"|"show"|"">("");

    const putProductInCart = (event: any) => {
        event.stopPropagation();
        dispatch(addProductToCart({product, quantity: 1}));
        setShowCartModal(true);
    }

    const navigateToProductScreen = () => {
        navigate(`/products/${product.id}`);
    }

    const setNewBigImage = (image: IProductImage) => {
        setBigImageClassName("hide");
        setTimeout(() => setSelectedImage(image), 200);
        setTimeout(() => setBigImageClassName("show"), 400);
    }

    return (
        <Card className={`${!big ? 'productCard hoverBlock' : 'bigProductCard'}`} onClick={navigateToProductScreen}>
            <div className={`productCardMedia ${bigImageClassName}`}>
                <CardMedia
                    component="img"
                    alt={selectedImage.name}
                    image={selectedImage.url}
                    className={''}
                />
            </div>
            {big ?
                <div className={"productCardMediaSelect"}>
                    {product.images.map((image: IProductImage) =>
                        <div
                            className={"productCardMediaSelectBlock hoverBlock"}
                            onClick={() => setNewBigImage(image)}
                            style={{backgroundImage: `url(${image.url})`}}
                        />
                    )}
                </div>
            : null}
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    noWrap
                >
                    <b>{product.name}</b>
                </Typography>
                <Typography
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: !big ? 'vertical' : undefined,
                        WebkitLineClamp: !big ? 3 : undefined,
                    }}
                    variant="body2"
                    color="text.secondary"
                >
                    {product.description}
                </Typography>
            </CardContent>
            <CardContent style={{display: "flex", flexDirection: "column", alignSelf: "flex-start"}}>
                {product.specifications.map((specification: IProductSpecification) =>
                    <Typography
                        gutterBottom
                        noWrap
                        style={{marginRight: 16}}
                        key={`product_${product.id}_specification_${specification.key}_${specification.value}`}
                    >
                        {specification.key}: <b>{specification.value}</b>
                    </Typography>
                )}
            </CardContent>
            <div className={"productCardActions"}>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="productCardPrice"
                >
                    <b>{price}</b>
                </Typography>
                <Button
                    size="medium"
                    variant={cartItem ? "outlined" : "contained"}
                    startIcon={cartItem ? <CheckIcon /> : <AddIcon />}
                    endIcon={<AddShoppingCartIcon />}
                    onClick={putProductInCart}
                />
            </div>
            <CartModal
                open={showCartModal}
                onClose={() => setShowCartModal(!showCartModal)}
                product={product}
            />
        </Card>
    );
}

export default ProductCard;