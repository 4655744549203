import {createHashRouter} from "react-router-dom";
import CartScreen from "./pages/CartScreen/CartScreen";
import HomeScreen from "./pages/HomeScreen/HomeScreen";
import CheckoutScreen from "./pages/CheckoutScreen/CheckoutScreen";
import ProductScreen from "./pages/ProductScreen/ProductScreen";
import LoginScreen from "./pages/LoginScreen/LoginScreen";
import RegisterScreen from "./pages/RegisterScreen/RegisterScreen";
import AccountScreen from "./pages/AccountScreen/AccountScreen";

const router = createHashRouter([
    {
        path: "/",
        Component: HomeScreen
    },
    {
        path: "/products",
        Component: HomeScreen
    },
    {
        path: "/cart",
        Component: CartScreen
    },
    {
        path: "/cart/checkout",
        Component: CheckoutScreen
    },
    {
        path: "/products/:productId",
        Component: ProductScreen
    },
    {
        path: "/login",
        Component: LoginScreen
    },
    {
        path: "/register",
        Component: RegisterScreen
    },
    {
        path: "/account",
        Component: AccountScreen
    },
]);

export default router;