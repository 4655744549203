import React, {useState} from "react";
import {
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import {useAppDispatch} from "../../../hooks/store";
import {addQuantity, removeProductFromCart} from "../../../store/cart";
import ICartItem from "../../../utils/interfaces/ICartItem";
import "./cartItem.scss";

interface IProps {
    cartItem: ICartItem
    outsideCart?: boolean;
    unchangeable?: boolean;
}
const CartItem: React.FC<IProps> = ({
    cartItem,
    outsideCart,
    unchangeable
}) => {
    const dispatch = useAppDispatch();
    const product = cartItem.product;
    const price = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(product.price);
    const totalPrice = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(product.price * cartItem.quantity);
    const [showConfirmBox, setShowConfirmBox] = useState(false)
    const changeQuantity = (value: string | number) => {
        const valueAsNumber = Number.isNaN(value) ? parseInt(value as string) : value as number;
        if (valueAsNumber <= 0) {
            dispatch(removeProductFromCart(product.id));
        } else {
            dispatch(addQuantity({product, quantity: valueAsNumber}))
        }
    }

    const deleteProductFromCart = () => {
        changeQuantity(0);
        toggleConfirmBox();
    }

    const toggleConfirmBox = () => {
        setShowConfirmBox(!showConfirmBox)
    }

    const renderConfirmBox = () => {
        return (
            <Dialog
                open={showConfirmBox}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Verwijderen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Weet u zeker dat u het product uit uw winkelwagentje wilt verwijderen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={toggleConfirmBox}>
                        Annuleren
                    </Button>
                    <Button onClick={deleteProductFromCart}>Verwijderen</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Card className={"cartItem"}>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Grid
                    item
                    xs={12}
                    sm={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{display: "flex"}}
                >
                    <CardMedia
                        component="img"
                        alt={product.images[0].name}
                        image={product.images[0].url}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    className="cartItemTitleContainer"
                >
                    <Typography variant="h6" className={"cartItemTitle"}>{product.name}</Typography>
                    <Typography
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                        }}
                        variant="body2"
                        color="text.secondary"
                    >
                        {product.description}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className="cartItemPriceContainer"
                    justifyContent="flex-end"
                >
                    <div className="cartItemQuantity">
                        <TextField
                            id="outlined-number"
                            label="Aantal"
                            type="number"
                            value={cartItem.quantity}
                            onChange={(event) => changeQuantity(event.target.value)}
                            disabled={unchangeable}
                        />
                        {!outsideCart && <Button
                            size="small"
                            variant={"text"}
                            onClick={toggleConfirmBox}
                            color="error"
                            sx={{marginTop: 1}}
                        >Verwijderen</Button>}
                        <Typography sx={{marginTop: 1}}><b>{totalPrice}</b></Typography>
                    </div>
                </Grid>
            </Grid>
            {renderConfirmBox()}
        </Card>
    );
}

export default CartItem;