import React, {useEffect, useState} from "react";
import {Button, Modal, Typography,} from '@mui/material';
import {useAppSelector} from "../../../hooks/store";
import {getCartItemByProductId} from "../../../store/cart";
import IProduct from "../../../utils/interfaces/IProduct";
import CartItem from "../CartItem/CartItem";
import ProductCard from "../../products/ProductCard/ProductCard";
import "./cartModal.scss";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useNavigate} from "react-router-dom";
import {getWindowDimensions} from "../../../utils/functions";

interface IProps {
    open: boolean;
    onClose: () => void;
    product: IProduct;
}
const CartModal: React.FC<IProps> = ({
    open,
    onClose,
    product
}) => {
    const navigate = useNavigate();
    const cartItem = useAppSelector((state) => getCartItemByProductId(state, product.id));
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function handleWindowResize() {
        setWindowDimensions(getWindowDimensions());
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const navigateToProducts = (event: any) => {
        event.stopPropagation();
        navigate("/products");
    }

    const navigateToCart = (event: any) => {
        event.stopPropagation();
        navigate("/cart");
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="cartModalContainer"
        >
            <div className="cartModal">
                <Typography variant="h5" style={{marginBottom: 16}}>Toegevoegd aan uw winkelwagentje!</Typography>
                {cartItem ?
                    <CartItem cartItem={cartItem} outsideCart={true} />
                    :
                    <ProductCard product={product} />
                }
                <div className="cartModalActions">
                    <Button
                        size="medium"
                        variant={"outlined"}
                        startIcon={<ArrowBack />}
                        onClick={navigateToProducts}
                    >{windowDimensions.width < 500 ? '' : 'Verder winkelen'}</Button>
                    <Button
                        size="medium"
                        variant={"contained"}
                        startIcon={windowDimensions.width < 500 ? <ShoppingCartIcon /> : undefined}
                        endIcon={<ArrowForward />}
                        onClick={navigateToCart}
                    >{windowDimensions.width < 500 ? '' : 'Naar winkelwagentje'}</Button>
                </div>
            </div>
        </Modal>
    );
}

export default CartModal;