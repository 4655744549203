import React from "react";
import "./footer.scss";
import GitHubIcon from '@mui/icons-material/GitHub';
import DeleteIcon from '@mui/icons-material/Delete';
import {Typography} from "@mui/material";
const Footer: React.FC = () => {
    return (
        <div className={"footer"}>
            <div className={"footerBlock hoverBlock"} onClick={() => window.open('https://github.com/harolddevries/react-test-shop', '_blank')}>
                <GitHubIcon className={"footerBlockIcon"}/>
                <Typography variant="body2" className={"cartItemTitle"}><b>bekijk code op GitHub</b></Typography>
            </div>
        </div>
    )
}

export default Footer;