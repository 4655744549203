import IProduct from "./interfaces/IProduct";
import IProductImage from "./interfaces/IProductImage";

export const generateFullName = (first_name: string, initials: string|undefined, last_name: string) => {
    let fullName = first_name;
    if (initials) {
        fullName += ` ${initials}`;
    }
    fullName += ` ${last_name}`;
    return fullName;
}

export const generateAvatarChars = (first_name: string, initials: string|undefined, last_name: string) => {
    const fullName = generateFullName(first_name, initials, last_name);
    const nameParts = fullName.split(" ");
    const firstPart = nameParts[0];
    const lastPart = nameParts[(nameParts.length-1)];
    const firstChar = firstPart.substring(0, 1);
    const lastChar = lastPart.substring(0,1);
    return `${firstChar}${lastChar}`.toUpperCase();
}
export const getRandomNumber = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
export const generateProducts = async () => {
    const colors: Array<string> = ["blue", "gray", "green", "red", "yellow"];
    const types: Array<string> = ["", "rain", "thunder"];

    const products = new Array<IProduct>();

    types.map((type: string, typeIndex: number) => {
        colors.map((color: string, colorIndex: number) => {
            const images = new Array<IProductImage>();
            const productId = parseInt(`${typeIndex}${colorIndex}`);
            for (const tp of types) {
                const imageName = `${tp ? tp + "_" : ""}${color}.png`;
                const id = images.length + 1
                images.push({
                    id: id,
                    product_id: productId,
                    name: imageName,
                    url: `/images/clouds/${imageName}`,
                    order: id,
                });
            }

            const specifications = [
                {id: 0, product_id: productId, key: "color", value: color},
            ]
            if (type) specifications.push({id: 1, product_id: productId, key: "type", value: type});
            products.push({
                id: productId,
                name: `${translateColor(color)} ${translateType(type)}`,
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                price: Math.floor(Math.random() * 100),
                images,
                specifications
            } as IProduct)
        });
    });

    return products;
}

const translateColor = (value: string) => {
    switch(value) {
        case "blue":
            return "blauw";
        case "red":
            return "rood";
        case "green":
            return "groen";
        case "yellow":
            return "geel";
        case "gray":
            return "grijs";
        default:
            return value;
    }
}

const translateType = (value: string) => {
    switch(value) {
        case "rain":
            return "regenwolk";
        case "thunder":
            return "onweerswolk";
        default:
            return "wolk";
    }
}
