import {Breadcrumbs, Link} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import React, {useEffect, useState} from "react";
import "./pageBreadcrumbs.scss";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../hooks/store";
import {getProductById, getProducts} from "../../../store/products";
import IProduct from "../../../utils/interfaces/IProduct";

const PageBreadcrumbs: React.FC = () => {
    const {productId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const products = useAppSelector((state) => getProducts(state));
    const [links, setLinks] = useState(Array<string>());
    const [product, setProduct] = useState<IProduct|undefined>();

    useEffect(() => {
        makeBreadCrumbs();
    }, [product]);

    useEffect(() => {
        getProduct();
    }, [productId]);

    const getProduct = () => {
        if (productId) {
            const productFromStore = products.find((prdct: IProduct) =>
                prdct.id === parseInt(productId)
            );
            if (productFromStore?.name) {
                setProduct(productFromStore);
                makeBreadCrumbs();
            }
        }
    }

    const makeBreadCrumbs = () => {
        const newLinks = [''];
        let paths = location.pathname.split("/");
        paths = paths.filter((path: string) => path !== '');
        for (const path of paths) {
            newLinks.push(path);
        }
        if (product?.name) {
            newLinks[paths.length] = product.name;
        }
        setLinks(newLinks);
    }

    const navigateToScreen = (index: number) => {
        let url = '/'
        for (let i = 0; i <= index; i++) {
            if (links[i] !== '') {
                url += `${links[i]}/`
            }
        }
        if (url.length > 1) {
            url.slice(0, -1);
        }
        navigate(url);
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" className="pageBreadCrumbs">
            {links.map((link, index) =>
                <Link
                    key={`link_${link}`}
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
                    color="inherit"
                    onClick={() => navigateToScreen(index)}
                    className={(index+1) === links.length ? "activePageBreadCrumb" : "pageBreadCrumb"}
                >
                    {link === "" ? <><HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> home</> : link}

                </Link>
            )}
        </Breadcrumbs>
    );
}

export default PageBreadcrumbs;