import React, {useState} from "react";
import Layout from "../Layout/Layout";
import './accountScreen.scss';
import {useAppSelector} from "../../hooks/store";
import {getUser, logoutUser} from "../../store/auth";
import {useNavigate} from "react-router-dom";
import {Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import IOrderItem from "../../utils/interfaces/IOrderItem";
import OrderItem from "../../components/orders/OrderItem/OrderItem";
import {getOrdersFromUser} from "../../store/orders";
import IOrder from "../../utils/interfaces/IOrder";
import {generateAvatarChars, generateFullName} from "../../utils/functions";

const AccountScreen: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useAppSelector((state) => getUser(state));
    const orders = useAppSelector((state) => getOrdersFromUser(state, user.email))
    const [selectedOrder, setSelectedOrder] = useState<IOrder|undefined>();

    const logout = () => {
        dispatch(logoutUser());
        setTimeout(() => navigate("/"), 1500);
    }

    const navigateToLogin = () => {
        navigate("/login");
    }

    return (
        <Layout>
            <div className="accountScreen">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={"accountScreenHeader"}>
                            <div className={"accountScreenHeaderName"}>
                                <div className={"accountScreenHeaderIcon"}>
                                    {generateAvatarChars(user.first_name, user.initials, user.last_name)}
                                </div>
                                <Typography variant="h5" className={"accountScreenHeaderText"}>{generateFullName(user.first_name, user.initials, user.last_name)}</Typography>
                            </div>
                            {!user?.email ?
                            <Button
                                size="large"
                                variant={"contained"}
                                sx={{marginTop: 2}}
                                onClick={navigateToLogin}
                            >Inloggen</Button>
                            :
                            <Button
                                size="large"
                                variant={"contained"}
                                sx={{marginTop: 2}}
                                onClick={logout}
                            >Uitloggen</Button>
                        }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {orders.map((order: IOrder) =>
                            <div key={`order_${order.id}`} onClick={() => setSelectedOrder(order)}>order: {order.orderNumber}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {selectedOrder ?
                            <>
                                {selectedOrder.items.map((orderItem: IOrderItem) =>
                                    <OrderItem key={`order_item_${orderItem.product.id}`} orderItem={orderItem} />
                                )}
                            </>
                        : null}
                    </Grid>
                </Grid>
            </div>
        </Layout>
    )
}

export default AccountScreen;