import React from "react";
import {useAppSelector} from "../../../hooks/store";
import {getProducts} from "../../../store/products";
import IProduct from "../../../utils/interfaces/IProduct";
import ProductCard from "../../../components/products/ProductCard/ProductCard";
import "./productsList.scss";
import {Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import {getCartItems} from "../../../store/cart";
import ICartItem from "../../../utils/interfaces/ICartItem";
import IProductImage from "../../../utils/interfaces/IProductImage";

const ProductsList: React.FC = () => {
    const products = useAppSelector(state => getProducts(state));
    const cartItems = useAppSelector(state => getCartItems(state));
    return (
        <Grid
            container
            spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            padding={2}
            className={"productsList"}
        >
            {products.map((product: IProduct) => {
                const cartItem = cartItems.find((cI: ICartItem) =>
                    cI.product.id === product.id
                );
                return (
                    <Grid
                        key={`product_${product.id}`}
                        item
                        xs={4}
                        md={3}
                    >
                        <ProductCard product={product} cartItem={cartItem}/>
                    </Grid>
                );
            })}
        </Grid>
    )
}

export default ProductsList;