import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'
import IProduct from "../utils/interfaces/IProduct";
import ICart from "../utils/interfaces/ICart";
import ICartItem from "../utils/interfaces/ICartItem";

interface CartState extends ICart {}

const initialState: CartState = {
    items: new Array<ICartItem>(),
    email: "",
    first_name: "",
    initials: "",
    last_name: "",
    delivery_address: "",
    delivery_zipcode: "",
    delivery_city: "",
    delivery_country: "",
    invoice_address: "",
    invoice_zipcode: "",
    invoice_city: "",
    invoice_country: "",
}

export const cartSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        mutateCart: (state, action: PayloadAction<ICart>) => {
            state = action.payload
        },
        addProductToCart: (state, action: PayloadAction<{product: IProduct, quantity: number}>) => {
            const index = state.items.findIndex((stateCartItem) =>
              stateCartItem.product.id === action.payload.product.id
            );
            if (index >= 0) {
                const cartItemInStore = state.items[index];
                cartItemInStore.quantity++;
                state.items[index] = cartItemInStore;
            } else {
                const cartItem = {
                    product: action.payload.product,
                    quantity: 1,

                } as ICartItem;
                state.items.push(cartItem);
                // toast.success(`${action.payload.product.name} toegevoegd aan uw winkelwagen`)
            }
        },
        removeProductFromCart: (state, action: PayloadAction<number>) => {
            const id = action.payload;

            const index = state.items.findIndex((stateCartItem) =>
                stateCartItem.product.id === id
            );

            if (index >= 0) {
                state.items.splice(index, 1);
            }
        },
        removeAllProductsFromCart: (state) => {
            state.items = new Array<ICartItem>();
        },
        addQuantity: (state, action: PayloadAction<{product: IProduct, quantity: number}>) => {
            const {product, quantity} = action.payload
            const index = state.items.findIndex((stateCartItem) =>
                stateCartItem.product.id === product.id
            );
            if (index >= 0) {
                const cartItemInStore = state.items[index];
                cartItemInStore.quantity = quantity;
                state.items[index] = cartItemInStore;
            } else {
                const cartItem = {
                    product: product,
                    quantity: 1,

                } as ICartItem;
                state.items.push(cartItem);
            }
        },
    },
})

export const {
    mutateCart,
    addProductToCart,
    removeProductFromCart,
    removeAllProductsFromCart,
    addQuantity
} =  cartSlice.actions

// selectors
export const getCartItems = (state: RootState) => state.cart.items;

export const getCartItemByProductId = (state: RootState, productId: number) =>
  state.cart.items.find((cI: ICartItem) => cI.product.id === productId);

export default cartSlice.reducer