import {combineReducers, configureStore} from '@reduxjs/toolkit'
import authReducer from "./store/auth";
import productsReducer from "./store/products";
import ordersReducer from "./store/orders";
import cartReducer from "./store/cart";
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    auth: authReducer,
    products: productsReducer,
    orders: ordersReducer,
    cart: cartReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch