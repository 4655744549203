import React, {ReactNode} from "react";
import Header from "./Header/Header";
import {ToastContainer} from "react-toastify";
import "./layout.scss";
import 'react-toastify/dist/ReactToastify.css';
import {Divider} from "@mui/material";
import PageBreadcrumbs from "./PageBreadcrumbs/PageBreadcrumbs";
import Footer from "./Footer/Footer";

interface IProps {
    children: ReactNode
}
const Layout: React.FC<IProps> = ({
   children
}) => {
    return (
        <div className={"layout"}>
            <Header/>
            <PageBreadcrumbs />
            <Divider className="divider" />
            <div className={"content"}>
                {children}
            </div>
            {/*<Divider className="divider" />*/}
            <Footer/>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                theme="colored"
            />
        </div>

    )
}

export default Layout;