import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks/store";
import Layout from "../Layout/Layout";
import './productScreen.scss';
import {useParams} from "react-router-dom";
import ProductCard from "../../components/products/ProductCard/ProductCard";
import IProduct from "../../utils/interfaces/IProduct";
import {getProducts} from "../../store/products";

const ProductScreen: React.FC = () => {
    const {productId} = useParams();
    const products = useAppSelector(state => getProducts(state));
    const [product, setProduct] = useState<IProduct|undefined>();

    useEffect(() => {
        getProduct();
    }, []);

    const getProduct = () => {
        if (productId) {
            const productFromStore = products.find((prdct: IProduct) =>
                prdct.id === parseInt(productId)
            );
            if (productFromStore?.name) {
                setProduct(productFromStore);
            }
        }
    }

    return (
        <Layout>
            {product ? <ProductCard product={product} big={true} /> : null}
        </Layout>
    )
}

export default ProductScreen;