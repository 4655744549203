import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'
import IOrder from "../utils/interfaces/IOrder";

interface OrdersState {
    orders: Array<IOrder>
}

const initialState: OrdersState = {
    orders: new Array<IOrder>(),
}

export const orderssSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        addOrder: (state, action: PayloadAction<IOrder>) => {
            state.orders.push(action.payload);
        }
    },
})

export const {
    addOrder
} = orderssSlice.actions

// selectors
export const getOrders = (state: RootState) => state.orders.orders

export const getOrdersFromUser = (state: RootState, email: string) =>
    state.orders.orders.filter((order: IOrder) => order.email === email);


export default orderssSlice.reducer